<template>
  <div class="login">
    <v-container>
      <v-row class="mt-10 flex-column align-center">
        <div>
          <v-col cols="12" sm="12" md="12">
            <v-img max-height="270" max-width="270" src="../assets/Mendoza-Fiduciaria-logo.png"></v-img>
            <h2 class="text-center">Iniciar sesi&oacute;n</h2>
          </v-col>
        </div>
      </v-row>
      <v-row>
        <v-col cols="12" offset-md="2" md="8" offset-sm="2" sm="8" offset-lg="4" lg="4">
          <v-form>
            <v-text-field label="Dirección de e-mail" v-model="email" v-on:keyup.enter="login"> </v-text-field>
            <v-text-field label="Contraseña" type="password" v-model="password" v-on:keyup.enter="login">
            </v-text-field>
            <div class="text-center">
              <v-btn color="success" @click="login"> Ingresar </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" offset-md="3" md="6" offset-sm="2" sm="8">
          <div class="text-center">
            <v-btn color="info" @click="register"> Registrarse </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" offset-md="3" md="6" offset-sm="2" sm="8">
          <v-alert :value="alert" color="#F29398" dark border="top" icon="mdi-cancel" transition="scale-transition">
            <v-row align="center">
              <v-col class="grow">{{ this.mensaje }}</v-col>
              <v-col class="shrink">
                <v-btn color="#EC6D75" @click="cerrarBoton">X</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'Login',
  data: () => ({
    email: localStorage.getItem('email') ? localStorage.getItem('email') : '',
    password: '',
    mensaje: '',
    alert: false,
  }),
  methods: {
    async checkOper() {
      try {
        const response = await axios.get(
          `${API_URL}api/servicioClientes/listado/${localStorage.getItem('cliente_id')}`,
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          },
        );

        if (response.data.status !== 'error') {
          return response.data.lineaCreditoClientes.length > 0;
        }
        return false;
      } catch (error) {
        console.error('Api-Error', error);
        return false;
      }
    },
    login() {
      axios
        .post(`${API_URL}api/usuarios/login`, {
          email: this.email,
          password: this.password,
          from: 'client',
        })
        .then(async (response) => {
          if (response.data.status === 'error') {
            this.mensaje = response.data.message;
            this.alert = true;
          } else {
            this.alert = false;
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('usuario_id', response.data.usuario_id);
            localStorage.setItem('cliente_id', response.data.cliente_id);
            localStorage.setItem('emailStatus', response.data.emailStatus);
            localStorage.setItem('usuarioNombre', response.data.nombre);
            localStorage.setItem('usuarioApellido', response.data.apellido);
            this.$bus.$emit('login', true);

            const check = await this.checkOper();

            if (!check) {
              this.$router.push('/catalogo');
            } else {
              this.$router.push('/');
            }
          }
        })
        .catch(() => {
          this.mensaje = 'Hubo un problema al iniciar sesión';
          this.alert = true;
        });
    },
    register() {
      this.$router.push('/register');
    },
    cerrarBoton() {
      this.alert = false;
    },
    redireccion() {
      const token = localStorage.getItem('token');
      if (token !== 'undefined' && token !== null && token !== '') {
        axios
          .post(`${API_URL}api/usuarios/validarToken`, {
            token: localStorage.getItem('token'),
          })
          .then(() => {
            this.$router.push('/');
          });
      }
    },
  },
  created() {
    this.redireccion();
  },
};
</script>
